.wrapper {
    width: 100%;
    height: 100%;
    background-color: #254441;
    display: flex;

    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding:25px;
}

.container {
    position: relative;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-gap: 25px;
    background: white;
    border-radius: 5px;
    padding: 0px;
    will-change: width, height, display, border-radius, padding;

    border-radius: 50%; 
    overflow: hidden; 
    display: grid; 
    justify-content: space-between;
    align-items: center; 
    padding-bottom: 10vh;
    max-width: 1000px;
    max-height: 1000px;
    /* box-shadow: 10px 10px 12px rgb(80, 80, 80); */
}

.item {
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 5px;
    will-change: transform, opacity;
    display: flex; 
    align-items: center; 
    justify-content: center;
    height: 100%;
    cursor: pointer;
}

