.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.circular-video {
  width: 350px; 
  height: 350px; 
  border-radius: 50%; 
  overflow: hidden; 
  display: grid; 
  justify-content: center; 
  align-items: center; 
  box-shadow: 10px 10px 12px rgb(80, 80, 80);
}


.TypeWriterContainer {
  padding-top: 30px;
  padding-bottom: 80px;
  font-size: calc(10px + 5vmin);
  height: calc(10px + 5vmin);
  /* text-shadow: 0 0 5px rgb(80, 80, 80);; */
}


*,
*:after,
*:before {
  box-sizing: border-box;
}

.flex {
  display: flex;
  align-items: center;
}

.flex.fill {
  height: 100%;
}

.flex.center {
  justify-content: center;
}